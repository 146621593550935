import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import StatusBadge from "@Eikochain/components/ui/suppliers-information-requests-table/supplier-information-request-status-badge";
import useGetSupplierInformationRequestById from "@Eikochain/graphql/hooks/useGetSupplierInformationRequestById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { Check, CheckIcon, Copy, CopyIcon, FolderDownIcon, X } from "lucide-react";
import { Link, redirect, useParams } from "react-router-dom";
import type { ColumnDef } from "@tanstack/react-table";
import DataTable from "@Eikochain/components/ui/data-table";
import { Button } from "@Eikochain/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@Eikochain/components/ui/dialog";
import { RadioGroup, RadioGroupItem } from "@Eikochain/components/ui/radio-group";
import { useState } from "react";
import { Label } from "@Eikochain/components/ui/label";
import { Badge } from "@Eikochain/components/ui/badge";

type Data = SupplierInformationResponse["supplierInformationRequest"];

interface DataRow {
    stepNo: number;
    title: string;
    description?: string;
    inputType: string;
    measurement: string;
}

const columns: ColumnDef<DataRow>[] = [
    {
        header: ({ }) => <p className="text-black font-bold">Step</p>,
        accessorKey: "stepNo",
    },
    {
        header: "Title",
        accessorKey: "title",
    },
    {
        header: "Description",
        accessorKey: "description",
    },
    {
        header: "Input type",
        cell: ({ row }) => <p>{row.original.inputType.at(0)?.toUpperCase() + row.original.inputType.slice(1)}</p>
    },
    {
        header: "Measurement",
        cell: ({ row }) => <p>{row.original.measurement === "" ? row.original.inputType : row.original.measurement}</p>
    }
]


export const StepDetailsTable: React.FC<{ data: Data }> = ({ data }) => {
    return (
        <DataTable
            data={data?.steps ?? []}
            columns={columns}
            isFetching={false}
            showRowCount={false}
            showColumnToggler={false}
            hidePaginationButtons={true}
        />
    )
};

const generateBlobAnchor = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = filename || "download";

    const clickHander = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            anchor.removeEventListener("click", clickHander);
        }, 150);
    }

    anchor.addEventListener("click", clickHander, false);
    return anchor
}

export const ExportResponsesDialogContent: React.FC<{ data: Data }> = ({ data: { responses, id } }) => {
    const [selectedFormat, setSelectedFormat] = useState("csv");

    const downloadCSV = () => {
        const csvHeader = "Request ID,Respondant Email,Respondant Name,Step title,Step description,Input type,Measurement,Response";

        const csv = responses?.map((response) => {
            return response!.steps.map((step) => {
                return `${id},${response!.email},${response!.name},${step!.title},${step!.description},${step!.inputType},${step!.measurement},${step!.response}`
            }).join("\n")
        }).join("\n");

        const csvData = [csvHeader, csv].join("\n");

        const blob = new Blob([csvData], { type: "text/csv" });
        const anchor = generateBlobAnchor(blob, "responses.csv");
        anchor.click();
    }

    return (
        <div className="flex flex-col gap-6">
            <div>
                <p className="text-xl font-semibold">Export responses</p>
                <p className="text-lg font-thin">Select the format you would like to export the responses in.</p>
            </div>
            <div>
                <RadioGroup
                    onValueChange={(v) => { setSelectedFormat(v) }}
                    defaultValue="csv"
                    className="flex gap-4"
                >
                    <div className="flex items-center space-x-2">
                        <RadioGroupItem value="csv" id="r1" />
                        <Label htmlFor="r1">CSV</Label>
                    </div>
                </RadioGroup>
            </div>

            <div>
                <Button onClick={() => {
                    switch (selectedFormat) {
                        case "csv":
                            downloadCSV();
                        default:
                            break;
                    }
                }}>Export</Button>
            </div>
        </div>
    )
}

export default function SupplierInformationRequestDetail() {
    const { id } = useParams<{ id: string }>();
    const [hasCopied, setHasCopied] = useState(false);

    if (!id) {
        redirect("/app/suppliers/requests");
    }

    const [result] = useGetSupplierInformationRequestById(id as string);
    const supplierInformationRequestById: Data = result?.data?.supplierInformationRequestById;
    const breadcrumbs = [
        {
            name: "Suppliers",
            url: "/app/suppliers/"
        },
        {
            name: "Supplier information requests",
            url: "/app/suppliers/requests"
        },
        {
            name: `${supplierInformationRequestById?.id} (Supplier Request)`,
        },
    ]

    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className="flex flex-col gap-10 mt-4 p-4">
                <div className="flex gap-4">
                    <h1 className="text-3xl font-medium">Request details</h1>
                    <Button
                        variant="outline"
                        onClick={() => {
                            setHasCopied(true);
                            navigator.clipboard.writeText("https://eikoiq.com/supplier-portal/request?id=" + supplierInformationRequestById?.id)
                        }}
                        className="flex gap-2"
                    >
                        {hasCopied && <CheckIcon className="h-6 w-6" />}
                        {!hasCopied && <CopyIcon className="h-6 w-6" />}
                        Copy link to form
                    </Button>
                </div>
                {supplierInformationRequestById && (
                    <>
                        {/* GENERAL INFO SECTION */}
                        <div>
                            <h2 className="text-xl">General information</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                                <div>
                                    <p className="text-sm font-semibold">Request ID</p>
                                    <p>{supplierInformationRequestById.id}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-semibold">Supplier</p>
                                    <p>{supplierInformationRequestById.supplier.name}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-semibold">Request date</p>
                                    <p>{supplierInformationRequestById.createdAt?.toString()}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-semibold">Status</p>
                                    <StatusBadge status={supplierInformationRequestById.status} />
                                </div>
                                <div>
                                    <p className="text-sm font-semibold">Request type</p>
                                    <p>{supplierInformationRequestById.requestType}</p>
                                </div>
                                {result.data.supplierInformationRequestById.additionalDetails && (
                                    <div>
                                        <p className="text-sm font-semibold">Additional details</p>
                                        <p>{supplierInformationRequestById.additionalInformation}</p>
                                    </div>
                                )}
                                <div>
                                    <p className="text-sm font-semibold">Is proof needed?</p>
                                    <p>{supplierInformationRequestById.requireProof ? <Check className="h-6 w-6 text-green-600" /> : <X className="h-6 w-6 text-red-500" />}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-semibold">Contacts this form has been sent to</p>
                                    <ul className="list-disc list-inside">
                                        {supplierInformationRequestById.supplierContact?.map((response) => (
                                            <li key={response!.id}>{response!.email}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* REQUEST STEPS SECTION */}
                        <div>
                            <h2 className="text-xl pb-4">Request steps</h2>
                            <StepDetailsTable data={supplierInformationRequestById} />
                        </div>

                        {/* RESPONSE SECTION */}
                        <div className="flex gap-2">
                            <Link to={`/app/suppliers/responses/${id}`}>
                                <Button className="">View {supplierInformationRequestById.responses?.length} response{(supplierInformationRequestById.responses?.length ?? 0) > 1 && "s"}</Button>
                            </Link>
                            {(supplierInformationRequestById.responses?.length ?? 0) > 0 && (
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button variant="secondary" className="flex gap-2">
                                            <FolderDownIcon className="h-4 w-4" />
                                            Export results
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent className="flex flex-col">
                                        <ExportResponsesDialogContent data={supplierInformationRequestById} />
                                    </DialogContent>
                                </Dialog>
                            )}
                        </div>
                    </>
                )}
            </div>
        </BaseLayout>
    )
}
