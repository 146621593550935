import { ComboBox, ComboBoxGroup, ComboBoxOption } from '@Eikochain/components/ui/add-supplier-information-request-stepper-form/add-supplier-information-request-form/fields/supplier-information-request';
import { Button } from '@Eikochain/components/ui/button';
import { CardHeader, CardTitle, Card, CardContent, CardDescription } from '@Eikochain/components/ui/card';
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@Eikochain/components/ui/chart';
import { Label } from '@Eikochain/components/ui/label';
import useGetSupplierInformationRequestById from '@Eikochain/graphql/hooks/useGetSupplierInformationRequestById';
import BaseLayout from '@Eikochain/layouts/BaseLayout';
import { Maybe, SupplierInformationResponse, SupplierInformationResponseDocument } from '@Eikochain/__generated__/graphql';
import { ArrowLeft, CheckIcon, DownloadIcon } from 'lucide-react';
import { FC, useState } from 'react';
import { Link, redirect, useParams } from 'react-router-dom';
import { Pie, PieChart } from 'recharts';
import CustomPieChart from '@Eikochain/components/ui/charts/custom-pie-chart';

type Data = SupplierInformationResponse["supplierInformationRequest"];
type Response = {
    id: string;
    name: string;
    email: string;
    steps: { response: string, stepNo: number, title: string, description?: string, measurement: string }[];
    document?: Maybe<SupplierInformationResponseDocument[]>;
    createdAt: string;
    orgName: string;
}

const breadcrumbs = [
    {
        name: "Suppliers",
        url: "/app/suppliers/"
    },
    {
        name: "Supplier information responses",
        url: "/app/suppliers/responses"
    },
]

const colours = ["#dc8a78", "#f2df64", "#86efad", "#5eead5", "#6477f2", "#2563eb", "#f29864", "#9864f2"]

// Components

const ResponderTypeChart: FC<{ responses: Response[] }> = ({ responses }) => {
    let chartData: { label: string, count: number, fill: string }[] = []

    responses.forEach((response) => {
        const org = chartData.findIndex((data) => data.label === response.orgName)
        if (org !== -1) {
            chartData[org].count += 1
        } else {
            chartData.push({ label: response.orgName, count: 1, fill: colours[chartData.length % colours.length] })
        }
    })

    return (
        <div className='flex flex-col'>
            <div className="items-center pb-0 flex flex-col">
                <CardTitle>Responder types</CardTitle>
                <CardDescription>Responder count per organisation</CardDescription>
            </div>
            <div className="flex-1 pb-0">
                <CustomPieChart
                    data={chartData}
                    dataTypeDisplayPluralised="responses"
                    isFetching={false}
                    includeLegend
                />
            </div>
        </div>
    )
}

const DocumentResponseChart: FC<{ responses: Response[] }> = ({ responses }) => {
    let chartData: { label: string, count: number, fill: string }[] = [{
        label: "Yes",
        count: 0,
        fill: colours[2]
    }, {
        label: "No",
        count: 0,
        fill: colours[0]
    }]

    responses.forEach((response) => {
        if (response.document && response.document.length > 0) {
            chartData[0].count += 1
        } else {
            chartData[1].count += 1
        }
    })

    return (
        <div>
            <div className="items-center pb-0 flex flex-col">
                <CardTitle>Responses with documents</CardTitle>
                <CardDescription>How many responses have documents</CardDescription>
            </div>
            <div className="flex-1 pb-0">
                <CustomPieChart
                    data={chartData}
                    isFetching={false}
                    includeLegend
                />
            </div>
        </div>
    )
}

const ContactResponsesChart: FC<{ responses: Response[], contacts: Data["supplierContact"] }> = ({ responses, contacts }) => {
    let chartData: { label: string, count: number, fill: string }[] = [
        {
            label: "Answered",
            count: 0,
            fill: colours[2]
        },
        {
            label: "Unanswered",
            count: 0,
            fill: colours[0]
        }
    ]

    contacts.forEach((contact) => {
        const response = responses.find((response) => response.email === contact?.email)
        if (response) {
            chartData[0].count += 1
        } else {
            chartData[1].count += 1
        }
    })

    return (
        <div>
            <div className="items-center pb-0 flex flex-col">
                <CardTitle>Responses from contacts</CardTitle>
                <CardDescription>How many responses were received from contacts</CardDescription>
            </div>
            <div className="flex-1 pb-0">
                <CustomPieChart
                    data={chartData}
                    isFetching={false}
                    includeLegend
                    customLabel={`${chartData[0].count} / ${contacts.length}`}
                    dataTypeDisplayPluralised='Answered'
                />
            </div>
        </div>
    )
}


const ResponseCard: FC<{ response: Response }> = ({ response }) => (
    <Card>
        <CardHeader>
            <CardTitle>Response Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <Label>Name</Label>
                    <div className="font-medium">{response.name}</div>
                </div>
                <div>
                    <Label>Email</Label>
                    <div className="font-medium">{response.email}</div>
                </div>
                <div>
                    <Label>Organisation</Label>
                    <div className="font-medium">{response.orgName}</div>
                </div>
            </div>
            {response.document && response.document.length !== 0 && (
                <div>
                    <Label>Files uploaded</Label>
                    <div className="mt-2 space-y-2">
                        {response.document.map((doc, idx) => (
                            <div key={idx} className="flex p-2 rounded gap-2">
                                <Button type="button" onClick={() => window.open(doc!.location, "_blank")?.focus()} className="flex gap-2">
                                    <DownloadIcon className="w-4 h-4" />
                                    <span>Proof {idx}</span>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div>
                <Label>Form Replies</Label>
                <div className="mt-2 space-y-2">
                    {response.steps.map(step => (
                        <div key={step.stepNo} className="bg-muted flex p-2 rounded gap-2">
                            <span className="font-semibold">{step.title} <span className='font-thin text-gray-700'>{step.description && `(${step.description})`}:</span></span>
                            <p>{step.response} {step.measurement}</p>
                        </div>
                    ))}
                </div>
            </div>
        </CardContent>
    </Card>
)
// Page
export const SupplierResponseDetail: FC = () => {
    const { id: requestId } = useParams<{ id: string }>();
    const [selectedResponse, setSelectedResponse] = useState<Response | undefined>(undefined);
    const [showAll, setShowAll] = useState(false);
    const [selectorOpen, setSelectorOpen] = useState(false);

    if (requestId === undefined) {
        redirect("/app/suppliers/requests");
        return <></>
    }

    const [result] = useGetSupplierInformationRequestById(requestId);

    if (result.fetching) {
        return <BaseLayout breadcrumbs={breadcrumbs}>Loading...</BaseLayout>
    }

    const data: Data = result.data?.supplierInformationRequestById;

    // Transform weird data structure into something more usable
    const responses: Response[] = data.responses!.map((response) => ({
        id: response!.id,
        name: response!.name,
        email: response!.email,
        steps: response!.steps.map((step) => ({
            response: step!.response,
            stepNo: step!.stepNo,
            title: step!.title,
            description: step!.description ?? undefined,
            measurement: step!.measurement
        })),
        document: response!.document,
        createdAt: response!.createdAt,
        orgName: data.supplierContact?.find((contact) => contact!.email === response!.email)?.supplier?.name || "Other"
    }))



    if (!responses || responses.length === 0) {
        return (
            <BaseLayout breadcrumbs={breadcrumbs}>
                <div className='w-full h-[30vh] flex flex-col items-center justify-center'>
                    <div className='flex flex-col gap-2'>
                        <p className='font-bold text-2xl'>No responses found for this request</p>
                        <Link to={`/app/suppliers/requests/view/${requestId}`} className="ml-[-2rem]">
                            <Button variant="link" className='flex gap-1'>
                                <ArrowLeft className='w-4 h-4' />
                                <span>Go back</span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </BaseLayout>
        )
    }


    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className='p-4 w-full flex flex-col gap-4'>
                <h1 className='text-2xl font-bold'>Supplier information responses for {data.supplier.name}</h1>
                <Link to={`/app/suppliers/requests/view/${requestId}`} className="mt-[-1rem] ml-[-1rem] pb-4">
                    <Button variant="link" className='flex gap-1'>
                        <ArrowLeft className='w-4 h-4' />
                        <span>Go back</span>
                    </Button>
                </Link>
                {/* Show some statistics */}
                <Card>
                    <CardHeader>
                        <CardTitle></CardTitle>
                    </CardHeader>
                    <CardContent className="gap-4 grid grid-cols-3 max-h-[500px]">
                        <ResponderTypeChart responses={responses} />
                        <DocumentResponseChart responses={responses} />
                        <ContactResponsesChart responses={responses} contacts={data.supplierContact} />
                    </CardContent>
                </Card>

                <div className='flex gap-2 mt-8'>
                    <ComboBox
                        open={selectorOpen}
                        setOpen={setSelectorOpen}
                        value={selectedResponse ? selectedResponse.email : undefined}
                        optionsSearchDisplayText="Search for a response"
                        noOptionsDisplayText="No responses found"
                        noValueDisplayText="Select a response"
                        className='w-fit'
                    >
                        <ComboBoxGroup>
                            {responses.map((response) => (
                                <ComboBoxOption
                                    key={response.email}
                                    value={response.email}
                                    className="flex gap-2"
                                    onSelect={() => {

                                        if (selectedResponse?.email === response.email) {
                                            setSelectedResponse(undefined)
                                            setSelectorOpen(false)
                                            setShowAll(false)
                                            return
                                        }

                                        setSelectedResponse(response);
                                        setShowAll(false);
                                        setSelectorOpen(false);
                                    }}
                                >
                                    {response.email === selectedResponse?.email && <CheckIcon className="w-4 h-4 text-green-500" />}
                                    {`${response.name} <${response.email}> (${response.orgName})`}
                                </ComboBoxOption>
                            ))}
                        </ComboBoxGroup>
                    </ComboBox>
                    {!showAll && <Button variant="secondary" onClick={() => {
                        setSelectedResponse(undefined)
                        setShowAll(true)
                    }}>
                        Show all
                    </Button>}

                    {showAll && <Button variant="secondary" onClick={() => {
                        setShowAll(false)
                        setSelectedResponse(undefined)
                    }}>
                        Hide all
                    </Button>}
                </div>



                {/* Details */}
                {selectedResponse && (
                    <ResponseCard response={selectedResponse} />
                )}

                {showAll && responses.map((response) => (
                    <ResponseCard key={response.id} response={response} />
                ))}
            </div>
        </BaseLayout >
    );
}
