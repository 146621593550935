import { SupplierInformationRequest, SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import Logo from "@Eikochain/components/ui/logo";
import Spinner from "@Eikochain/components/ui/spinner";
import SupplierInformationRequestForm from "@Eikochain/components/ui/supplier-information-request-form";
import useGetSupplierInformationRequestById from "@Eikochain/graphql/hooks/useGetSupplierInformationRequestById";
import { CheckCheckIcon } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

export default function SupplierInformationRequestPage() {
    const [valuesSent, setValuesSent] = useState<SupplierInformationResponse>()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const supplierRequestId = searchParams.get("id") as string;
    if (!supplierRequestId) navigate("/404/")
    const [result] = useGetSupplierInformationRequestById(supplierRequestId)

    if (result.error) navigate("/404/");

    const supplierInformationRequestById: SupplierInformationResponse["supplierInformationRequest"] = result?.data?.supplierInformationRequestById;

    return (
        <div className="w-full flex flex-col items-center">
            <div className="flex flex-col gap-12 items-center lg:w-2/3 p-16">
                <div className="flex flex-col items-center gap-2 max-w-[180px]">
                    <Logo />
                    <h3 className="text-indigo-600">SUPPLIER PORTAL</h3>
                </div>
                {
                    result.fetching ? <Spinner /> : (
                        valuesSent ? (
                            <div className="flex flex-col items-center justify-center gap-4">
                                <div className="flex items-center justify-center rounded-full p-2 bg-emerald-100">
                                    <div className="flex items-center justify-center rounded-full h-16 w-16 bg-emerald-700">
                                        <CheckCheckIcon className="text-white" />
                                    </div>
                                </div>
                                <h3 className="text-center">Sent response to {supplierInformationRequestById?.organization.name}</h3>
                                <Link to="https://eikoiq.com" target="_blank">
                                    <Button variant="fun">
                                        Get started tracking your own emissions with EikoIQ
                                    </Button>
                                </Link>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-col gap-2">
                                    <p className="text-balance">
                                        One of your customers, {supplierInformationRequestById?.organization.name}, has requested emissions data. Please fill out the form below to provide the requested information.
                                    </p>
                                    <p className="font-semibold">
                                        Description: <span className="font-thin">{supplierInformationRequestById.additionalInformation}</span>
                                    </p>
                                </div>
                                <div className="w-full">
                                    <SupplierInformationRequestForm
                                        supplierRequest={supplierInformationRequestById}
                                        onSuccess={(v) => setValuesSent(v)}
                                    />
                                </div>
                            </>
                        )
                    )
                }
            </div>
        </div>
    )
}
