import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../button";

const columns: ColumnDef<SupplierInformationResponse>[] = [
    {
        header: "Supplier",
        cell: ({ row }) => (
            <div className="flex flex-row items-center gap-4">
                <span>{row.original.supplierInformationRequest.supplier.name}</span>
            </div>
        ),
    },
    {
        id: "requestDetails",
        header: "Request details",
        cell: ({ row }) => (
            <div className="flex flex-col gap-2 max-w-[20vw]">
                <p><span className="font-bold">ID:</span> {row.original.supplierInformationRequest.id}</p>
                <p><span className="font-bold">Request type:</span> {row.original.supplierInformationRequest.requestType}</p>
                {row.original.supplierInformationRequest.additionalInformation !== "" && (
                    <p>
                        <span className="font-bold">Additional Information:</span>
                        {row.original.supplierInformationRequest.additionalInformation}
                    </p>
                )}
            </div>
        )
    },
    {
        id: "name",
        header: ({ column }) => {
            return (
                <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(!column.getIsSorted())}>
                    Respondant name
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => (
            <div className="flex flex-row items-center gap-4">
                <span>{row.original.name}</span>
            </div>
        )
    },
    {
        id: "email",
        header: "Email",
        cell: ({ row }) => (
            <div className="flex flex-row items-center gap-4">
                <span>{row.original.email}</span>
            </div>
        )
    },
    {
        id: "steps",
        header: "Reponses",
        cell: ({ row }) => (
            <div className="flex flex-row items-center gap-4 w-fit">
                <ul>
                    {row.original.steps.map((step) => (
                        <li key={step!.stepNo} className="flex gap-1">
                            <strong>{step!.title}:</strong>
                            <span>{step!.response} {step!.measurement}</span>
                        </li>
                    ))}
                </ul>
            </div>
        )
    },
]

export default columns;
